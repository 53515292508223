<template>
  <Browse
    :columns="[
      'name',
      'licence_expiration_date',
      'currency',
      'service_dues_preadvice_days',
      'service_dues_tolerance_days',
      'licence_time_stamp',
      'pwd_expiration_days',
    ]"
    :formats="{
      licence_expiration_date: 'Date',
      service_dues_preadvice_days: 'UpDown',
      service_dues_tolerance_days: 'UpDown',
      licence_time_stamp: 'DateTime',
      pwd_expiration_days: 'UpDown',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="businesses"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
