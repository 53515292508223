var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'name',
    'licence_expiration_date',
    'currency',
    'service_dues_preadvice_days',
    'service_dues_tolerance_days',
    'licence_time_stamp',
    'pwd_expiration_days' ],"formats":{
    licence_expiration_date: 'Date',
    service_dues_preadvice_days: 'UpDown',
    service_dues_tolerance_days: 'UpDown',
    licence_time_stamp: 'DateTime',
    pwd_expiration_days: 'UpDown',
  },"itemsPerPage":"10","searchOnStart":true,"table":"businesses"}})}
var staticRenderFns = []

export { render, staticRenderFns }